<template>
  <Banner bg-image="gray-bg.jpg" talk-img="faq.png">
    <template v-slot:title>Suicide FAQ</template>
    <template v-slot:subtitle>
      Most people have questions about suicide, including what it is, why people
      experience it and what exactly can be done to help prevent it.
    </template>
    <template v-slot:description>
      Let’s explore some definitions and answers to several of the most commonly
      asked questions about suicide.</template
    >
  </Banner>
  <!-- WHAT IS SUICIDE -->
  <section class="py-10 my-8 talk-sect md:my-12 md:py-1s4 gradient-white">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold text-center text-blue">
        What is suicide?
      </div>
      <div class="pt-8">
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">Suicide</span>
          </template>

          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Suicide means ending your own life, and is often related to
                complex stressors and health issues. Suicide occurs across all
                ages, incomes, ethnicity and social factors.
              </p>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"> Suicidal ideations (SI)</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Suicidal ideations are thoughts or ideas, about killing oneself.
                It is a broad term used to describe a range of
                contemplations/considerations, wishes, and preoccupations with
                death and suicide. It varies in intensity, duration, and
                character.
              </p>
            </div>
          </template>
        </Collapsible>

        <br />

        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"> Passive suicidal ideation</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Passive suicidal ideation occurs when you wish you were dead or
                that you could die, but you don’t actually have any plans to
                commit suicide.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">Active suicidal ideation</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Active suicidal ideation is not only thinking about it but
                having the intention, and/or including planning how to do it.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">Suicidal behaviour</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Suicidal behaviour is any suicidal thought or action.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">Suicidal intent</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                To have suicidal intent is to deliberate self-killing as an aim,
                purpose, or goal. It is not the behaviour itself, but rather the
                goal or purpose. This suggests a conscious desire or wish to
                leave or escape from life or the pain one is experiencing.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">Suicide plan</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Suicide plan, which refers to the preparation or design of a
                specific method through which one intends to die.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"
              >How do I know whether someone is considering suicide?</span
            >
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Look for warning signs for suicide that are related to the way
                the person is talking or acting. However, it’s not always
                obvious that someone is at risk, so the best way to know if
                someone is thinking about suicide is to ask them if you have
                concerns.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"
              >If I ask my friend/family member whether they’re thinking about
              suicide, will it put the idea into their head?</span
            >
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                No. Research has found that asking someone whether they are
                having suicidal thoughts does not give them suicidal tendencies.
                The actual risk is not talking about suicide with someone who
                may be in crisis.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"
              >What should I say to a friend/family member having suicidal
              thoughts?</span
            >
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                There are several ways to talk to a friend/family member
                thinking about suicide. To get started, see Our advice on
                beginning a dialogue with someone, asking the right questions,
                and knowing what to say and do based on what you learn from the
                conversation.
              </p>
            </div>
          </template> </Collapsible
        ><br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"
              >Is a person at increased risk to attempt suicide if they’ve been
              exposed to it in their family or has had a close friend who died
              by suicide?</span
            >
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                It’s possible. For young people without a mental health
                condition, being exposed to a family member’s or friend’s
                suicide attempt usually doesn’t increase their suicide risk in
                the absence of other risk factors, like substance misuse,
                trauma, abuse, or bullying. However, for young people with a
                mental health condition, being exposed to a family member’s or
                friend’s suicide attempt can put them at greater risk for
                attempting suicide.
              </p>
            </div>
          </template>
        </Collapsible>
        <br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl"
              >What can I do if I am thinking about suicide?</span
            >
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                Thinking about suicide is scary, confusing and isolating. You
                may have strong feelings that are hard to deal with, you may be
                feeling overwhelmed. You may feel like things will never be
                better, but you are not alone. Many young people think about
                suicide. Thinking about suicide does not mean that you will do
                it. It does not mean that you are “going crazy.” Thinking about
                suicide is often a sign that you want to escape from your pain.
                There are things you can and people you can talk too ,to help
                you manage and feel better. Remember, you are not alone. You can
                start by talking to a friend. You can talk to a family member or
                a professional you trust. You can also call a crisis line in
                your area. In Calgary call 403-266-HELP. It stands for
                403-266-4357. You can call this number whenever you need help.
                It is a free call. They are trained to help people. If you have
                a doctor or counsellor, tell them how you feel. They can teach
                you skills to deal with thoughts of suicide.
              </p>
            </div>
          </template> </Collapsible
        ><br />
        <Collapsible>
          <template v-slot:header>
            <span class="text-2xl">How can I protect myself from suicide?</span>
          </template>
          <template v-slot:content>
            <div class="px-8 bg-blue-100">
              <p class="py-4">
                None of us can say that we will never have thoughts of suicide.
                There are two important things you can do help prevent suicide.
                First, build connections with other young people, family,
                indigenous culture and practices. Second, find help for mental
                health & substance use problems.
              </p>
            </div>
          </template>
        </Collapsible>
      </div>
    </div>
  </section>
  <FooterCard
    img="help-for-someone-else-braid.png"
    text="Explore tools and resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>
      <p class="mt-2.5">
        Here are some tools and resources you can lean on now to help keep
        yourself safe.
      </p>
    </template>
    <template v-slot:content>&nbsp; </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Collapsible from "@/components/Collapsible.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "suicide-FAQ",
  components: {
    Banner,
    Collapsible,
    FooterCard,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
</style>
